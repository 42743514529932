import { authStore } from '@/state/authStore';
import { usersStore } from '@/state/usersStore';
import { humanizeDiffFilter, IRoseAGGridColumn } from '@rose/common-ui';
import { IUIUser } from '@rose/types';

export function settingsBenutzerColumnsDefs(): IRoseAGGridColumn<IUIUser>[] {
  return [
    {
      headerName: 'Bild',
      cellRenderer: 'AvatarCell',
      sortable: false,
      width: 70,
    },
    {
      headerName: 'Name',
      field: 'lastName',
      valueGetter: params => `${params.data?.firstName} ${params.data?.lastName}`,
      sortable: true,
      sort: 'asc',
      width: 200,
    },
    {
      headerName: 'Letzte Aktivität',
      field: 'lastActivity',
      valueFormatter: params =>
        params.data?.lastActivity
          ? humanizeDiffFilter.filters.humanizeFromNow(params.data.lastActivity, true)
          : 'Nie angemeldet',
      sortable: true,
      width: 120,
    },
    {
      headerName: 'E-Mail',
      field: 'email',
      cellRenderer: 'CopyCell',
      width: 300,
      sortable: true,
    },
    {
      headerName: 'Ext. Benutzer',
      headerTooltip: 'Externer Benutzer',
      valueGetter: params => params.data?.mainclientid !== authStore.getters.cid,
      cellRenderer: 'ExternalAccessCell',
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      width: 120,
    },
    {
      headerName: 'Ext. Zugriff',
      headerTooltip: 'Externer Zugriff',
      valueGetter: params => !params.data?.ipfiltering?.active,
      cellRenderer: 'CheckAndCrossCell',
      cellRendererParams: {
        context: {
          colorized: true,
        },
      },
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      width: 120,
    },
    {
      headerName: 'Zwei-Faktor-Login',
      cellRenderer: 'TwoFaCell',
    },
    {
      headerName: 'Erlaubte Views',
      cellRenderer: 'UserViewsCell',
      flex: 1,
      autoHeight: true,
      minWidth: 200,
    },
    {
      headerName: '',
      field: 'id',
      cellRenderer: 'ActionButtonsCell',
      width: 110,
      sortable: false,
      cellRendererParams: {
        context: {
          edit(user: IUIUser) {
            usersStore.commit.setSelectedUser(user);
            usersStore.commit.setShowModal(true);
          },
          clone(user: IUIUser) {
            usersStore.commit.setDuplicateUser(true);
            usersStore.commit.duplicateUser(user);
            usersStore.commit.setShowModal(true);
          },
          delete(user: IUIUser) {
            usersStore.commit.setSelectedUser(user);
            usersStore.commit.setShowDeleteDialog(true);
          },
        },
      },
    },
  ];
}
