const firstNameRules = [(v: string) => !!v || 'Vorname ist erforderlich'];

const lastNameRules = [(v: string) => !!v || 'Nachname ist erforderlich'];

const emailRules = [
  (v: string) => !!v || 'E-Mail ist erforderlich',
  (v: string) => /.+@.+/.test(v) || 'E-Mail muss gültig sein',
  (v: string) => !/\s/.test(v) || 'E-Mail darf keine Leerzeichen enthalten',
  (v: string) => !/–/.test(v) || 'E-Mail darf keine ungültigen Unicode-Zeichen enthalten (–)',
];

const passwordRules = [
  (v: string) => v.length >= 10 || 'Passwort muss mindestens 10 Zeichen lang sein',
  (v: string) => /[A-Z]/.test(v) || 'Passwort muss mindestens einen Großbuchstaben enthalten',
  (v: string) => /[a-z]/.test(v) || 'Passwort muss mindestens einen Kleinbuchstaben enthalten',
  (v: string) => /[0-9]/.test(v) || 'Passwort muss mindestens eine Zahl enthalten',
];

export const validationRules = {
  firstName: firstNameRules,
  lastName: lastNameRules,
  email: emailRules,
  password: passwordRules,

  minLength: (min: number) => (v: string) => v.length >= min || `Mindestens ${min} Zeichen`,
};
