import { defineComponent } from 'vue';
export default defineComponent({
  props: ['title', 'text', 'cancelButton', 'proceedButton'],
  data: function data() {
    return {};
  },
  methods: {
    closeDialog: function closeDialog(value) {
      this.$emit('dialog-response', value);
    }
  }
});